import { SectionContainer } from "../Section";
import Link from "next/link";
import Image from "next/image";
import { ButtonGroup } from "../Button/ButtonGroup";
import { Icon } from "@iconify/react";
import { useDialog } from "../../hooks/useDialog";

const DATA = [
  {
    title: "Na skróty",
    items: [
      {
        label: "O nas",
        href: "/o-nas",
      },
      {
        label: "Oferta",
        href: "#oferta",
      },
      {
        label: "Portfolio",
        href: "#portfolio",
      },
      {
        label: "Najczętsze pytania",
        href: "#faq",
      },
      {
        label: "Realizacje",
        href: "/wiecej-realizacji",
        target: "_blank",
      },
      {
        label: "Blog",
        href: "/blog",
        target: "_blank",
      },
    ],
  },
  {
    title: "Handyman Social Media",
    items: [
      {
        label: "Facebook",
        href: "https://www.facebook.com/p/Handyman-Konstrukcje-Drewniane-100063725341071/",
        target: "_blank",
      },
      {
        label: "TikTok",
        href: "https://www.tiktok.com/@__handyman__",
        target: "_blank",
      },
    ],
  },
];

export const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const { openDialog } = useDialog();

  return (
    <footer id="footer" className="bg-[#efefef]">
      {/* Footer Links */}
      <SectionContainer className="footer--container wrap wrap-px relative z-10">
        <div className="footer--content-container py-16">
          <div className="footer-links mb-12 grid grid-cols-2 gap-8 md:mb-16 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-6">
              <div className="footer--logo grid gap-8">
                <Link href="/">
                  <Image
                    src="/handyman-logo.webp"
                    alt="logo"
                    className="h-20 w-auto m-auto"
                    height="80"
                    width="110"
                    sizes="(max-width: 640px) 25vw, 20vw)"
                    priority
                  />
                </Link>
                <div
                  id="kontakt"
                  className="flex flex-col items-center justify-start"
                >
                  <h2 className="m-0 text-xl font-medium">HANDYMAN</h2>
                  <p className="text-sm m-0 mb-3 font-medium">Olaf Radziuk</p>
                  <p className="text-sm m-0">ul. Miła 1B, 73-110 Stargard</p>
                  <p className="text-sm m-0">NIP: 8542444909</p>
                  <p className="text-sm m-0 mb-3">REGON: 526028362</p>
                  <p className="text-sm m-0 mb-3">
                    email:{" "}
                    <a href="mailto:kontakt@handyman.com.pl">
                      kontakt@handyman.com.pl
                    </a>
                  </p>
                  <a
                    className="text-sm m-0 p-4 mb-2 font-bold btn bg-white"
                    href="tel:505403420"
                  >
                    tel. 505 403 420
                  </a>
                  <a
                    className="text-sm m-0 p-4 font-bold btn bg-white"
                    href="tel:732973655"
                  >
                    tel. 732 973 655
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-6">
              <div className="footer-menu grid grid-cols-2 md:grid-cols-8 lg:grid-cols-12">
                {DATA.map((footerLinks) => (
                  <div
                    key={footerLinks.title}
                    className="footer-menu--container col-span-1 md:col-span-4"
                  >
                    <h3 className="font-bold text-base mb-2">
                      {footerLinks.title}
                    </h3>
                    <ul className="footer-menu--list">
                      {footerLinks.items.map((footerItem) => (
                        <li
                          key={footerItem.label}
                          className="footer-menu--list-item gap-2"
                        >
                          <a
                            className="mb-2 block w-auto font-medium transition-colors duration-300 hover:underline"
                            href={footerItem.href}
                            target={footerItem.target}
                          >
                            {footerItem.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <ButtonGroup alignment="left" className="">
                <div
                  onClick={() => openDialog()}
                  className="btn btn--secondary"
                >
                  Poznaj wycenę
                  <Icon icon="material-symbols:arrow-forward-rounded" />
                </div>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </SectionContainer>
      {/* Footer Credits */}
      <SectionContainer className="footer-credits relative z-10">
        <div className="wrap wrap-px py-6">
          <p className="my-0">© {year} Handyman</p>
        </div>
      </SectionContainer>
    </footer>
  );
};
