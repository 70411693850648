import { getTextAlign } from "../../utils/helper";
import { SectionContainer } from "../Section";

export const ButtonGroup = ({ className = "", alignment, children }: {
    className?: string;
    alignment?: string;
    children: React.ReactNode;
}) => {
    const alignClass = getTextAlign(alignment);
    return (
        <SectionContainer className="btn-group">
            <div
                className={`btn-group--container justify-center flex ${className && className} ${
                    alignClass && alignClass
                }`}
            >
                {children}
            </div>
        </SectionContainer>
    );
};
