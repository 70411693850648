import { useState } from "react";
import { ButtonGroup } from "../Button/ButtonGroup";
import { Icon } from "@iconify/react";
import { useRouter } from "next/router";
import { useDialog } from "@hooks/useDialog";

const navigation: NavItemProps[] = [
  { name: "O nas", to: "/o-nas", href: "/o-nas" },
  { name: "Portfolio", to: "#portfolio", href: "/#portfolio" },
  { name: "Oferta", to: "#oferta", href: "/#oferta" },
  { name: "FAQ?", to: "#faq", href: "/#faq" },
  {
    name: "Kontakt",
    to: "#kontakt",
    href: "/#kontakt",
  },
  {
    name: "Blog",
    to: "/blog",
    href: "/blog",
    isArrow: true,
    target: "_blank",
  },
];

interface NavItemProps {
  name: string;
  to?: string;
  href: string;
  isArrow?: boolean;
  target?: string;
}

export const Nav = () => {
  const router = useRouter();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { openDialog } = useDialog();

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <nav className="header-nav">
      <div className="header-nav--container">
        <button
          onClick={() => setIsNavOpen(!isNavOpen)}
          data-collapse-toggle="navbar-default"
          type="button"
          className="mobile-menu"
          aria-controls="navbar-dropdown"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <Icon
            icon="material-symbols:menu-rounded"
            className="h-6 w-auto text-black"
          />
        </button>
        <div
          className={`header-nav--menu-container z-20 ${
            isNavOpen ? "show" : "hide"
          }`}
          id="navbar-default"
        >
          <ul className="header-nav--menu items-center">
            {navigation.map((item) => (
              <li key={item.name} className="header-nav--menu-item">
                <a
                  key={item.name}
                  href={item.href}
                  className={`menu-item--link flex items-center
                    ${router.pathname === item.href ? "active" : ""}
                  `}
                  onClick={closeNav}
                  target={item.target ? item.target : "_self"}
                >
                  {item.name}
                  {item.isArrow && (
                    <span className="ml-2 inline-block text-sm font-medium text-inherit">
                      <Icon
                        icon="material-symbols:arrow-outward"
                        className="h-6 w-auto"
                      />
                    </span>
                  )}
                </a>
              </li>
            ))}
            <ButtonGroup alignment="left" className="">
              <div onClick={() => openDialog()} className="btn btn--secondary">
                Poznaj wycenę
                <Icon icon="material-symbols:arrow-forward-rounded" />
              </div>
            </ButtonGroup>
          </ul>
        </div>
      </div>
    </nav>
  );
};
