export const siteMetadata = {
  title: `Handyman - Konstrukcje drewniane, zadaszenia wiaty tarasy | Stargard | Szczecin`,
  siteUrl: `https://handyman.com.pl`,
  description: `Handyman - Konstrukcje drewniane, zadaszenia wiaty tarasy | Stargard | Szczecin | Drawsko Pomorskie`,
  author: `Handyman Olaf Fradziuk`,
  siteLogo: "/handyman-logo.webp",
  keywords: [
    "handyman",
    "stargard",
    "tarasy",
    "konstrukcje drewniane",
    "zadaszenia",
    "wiaty",
    "szczecin",
    "drawsko pomorskie",
  ],
  twitterHandle: "@handyman",
};