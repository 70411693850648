import { ReactNode } from "react";

export const SectionContainer = ({
  id,
  children,
  className = "",
}: {
  id?: string;
  children: ReactNode;
  className: string;
}) => {
  return (
    <section id={id} className={`${className && className}`}>
      {children}
    </section>
  );
};
