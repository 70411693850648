import dynamic from 'next/dynamic';
import { Footer } from "../Footer";
import { Header } from "../Header";

const Dialog = dynamic(() => import("../Dialog"), { ssr: false });

export const Layout = ({ children, className = "" }) => {
  return (
    <>
      <main
        className={`main relative overflow-hidden ${className && className}`}
      >
        <Header />
        {children}
        <Footer />
      </main>
      <Dialog />
    </>
  );
};
