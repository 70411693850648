import Head from "next/head";
import { siteMetadata } from "../../config";

const SEO = ({
  title,
  description,
  link,
  keywords,
  ratingValue,
  ratingCount,
  image,
  contentType,
}: {
  title: string;
  description: string;
  link: string;
  keywords?: string;
  ratingValue?: number;
  ratingCount?: number;
  image?: string;
  contentType?: string;
}) => {
  const metaDescription = description ? description : siteMetadata.description;
  const metaKeywords = keywords ? keywords : siteMetadata.keywords.join(", ");
  const siteURL = siteMetadata.siteUrl;
  const twitterHandle = siteMetadata.twitterHandle;
  const imagePreview = image ? image : `${siteURL}${siteMetadata.siteLogo}`;
  const finalTitle = title === "" ? `${siteMetadata.title}` : title;

  const webpageSchema = {
    "@type": "WebPage",
    url: `${siteURL}${link}`,
    name: `${finalTitle}`,
    description: `${metaDescription}`,
    mainEntityOfPage: {
      "@type": contentType ? contentType : "WebPage",
      "@id": `${siteURL}`,
    },
    publisher: {
      "@type": "Person",
      name: `${siteMetadata.author}`,
      logo: {
        "@type": "ImageObject",
        url: `${siteURL}/${siteMetadata.siteLogo}`,
      },
    },
  };

  const ratingSchema = ratingValue &&
    ratingCount && {
      "@type": "CreativeWorkSeries",
      name: `${finalTitle}`,
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: `${ratingValue}`,
        ratingCount: `${ratingCount}`,
      },
    };

  const schemaWrapper = [];

  if (webpageSchema) {
    schemaWrapper.push(webpageSchema);
  }

  if (ratingSchema) {
    schemaWrapper.push(ratingSchema);
  }

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      {/* { Twitter } */}
      <meta name="twitte:card" content="summary_large_image" key="twcard" />
      <meta name="twitter:creator" content={twitterHandle} key="twhandle" />

      {/* {Open Graph} */}
      <meta property="og:url" content={siteURL} key="ogurl" />
      <meta property="og:image" content={imagePreview} key="ogimage" />
      <meta property="og:site_name" content={siteURL} key="ogsitename" />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={metaDescription} key="ogdesc" />
      <meta name="google-site-verification" content="-wzbL8gOpXeJQCkA6CsiVYkCJWIc6usYelPGh-DGUWc" />
      <link
        rel="apple-touch-icon"
        href="/icon-512x512.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d5a65b" />
      <title>{title}</title>
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="alternate" type="application/rss+xml" href="/rss.xml" />
      <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
      <link rel="canonical" href={`${siteURL}${link}`} />
      <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@graph": ${JSON.stringify(schemaWrapper)}
        }`}</script>
    </Head>
  );
};

export default SEO;
