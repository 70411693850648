import Link from "next/link";
import Image from "next/image";
import { SectionContainer } from "../Section";
import { Nav } from "../Nav";

export const Header = () => {
    return (
        <header
            id="header"
            className="header fixed left-0 w-full z-30 top-0 bg-white backdrop-filter backdrop-blur-md bg-opacity-50"
        >
            <SectionContainer id="header" className="header--container wrap wrap-px ">
                <div className="header-logo--container">
                    <h1 className="logo mb-0">
                        <Link href="/">
                            <Image
                                src="/handyman-logo.webp"
                                alt="logo"
                                className="h-20 w-auto"
                                height="80"
                                width="110"
                                sizes="(max-width: 640px) 25vw, 20vw)"
                                priority
                            />
                        </Link>
                    </h1>
                </div>
                <SectionContainer className="flex items-center ml-auto">
                    <Nav />
                </SectionContainer>
            </SectionContainer>
        </header>
    );
};
